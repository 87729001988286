import oNowTerp from './oNowTerp';
import oCredentials from './oCredentials';
import { oTerp } from './oTerp';
import oCompany from './oCompany';
import { oDeaf } from './oDeaf';
import oJobs from './oJobs';
import oDemands from './oDemands';
import oCompanyJobs from './summaries/oCompanyJobs';
import oFileUpload from './oFileUpload';
import oHours from './utils/oHours';
import oSqlMaint from './utils/oSqlMaint';
import oCron from './utils/oCron';
import oLogs from './utils/oLogs';
import oDash from './summaries/oDash';
import oSeek from './oSeek';
import oAccount from './oAccount';
import { oStaffChat } from './oStaffChat';
import { oJobLists } from './summaries/oJobLists';
import { oMiscQueryReports } from './summaries/oMiscQueryReports';
import { oBilling } from './oBilling';
import { oLocation } from './oLocation';
import { oJobSeries } from './oJobSeries';
import { oTerpChecklist } from './oTerpChecklist';
import { vContact } from './variance/vContact';
import { vTerp } from './variance/vTerp';
import { vCompany } from './variance/vCompany';
import { vDeaf } from './variance/vDeaf';
import { vCredReport } from './variance/vCredReport';
import { vBids } from './variance/vBids';
import { vStaffNews } from './variance/vStaffNews';
import { oStaffNews } from './oStaffNews';
import { vFinder } from './variance/vFinder';
import { oTerpSched } from './oTerpSched';
import { vTerpSched } from './variance/vTerpSched';
import { oJobEdit } from './oJobEdit';
import { vJobUpdate } from './variance/vJobUpdate';
import { oJobSeek } from './oJobSeek';
import { vJobSeek } from './variance/vJobSeek';
import { vTerpLists } from './variance/vTerpLists';
import { vDeafEditor } from './variance/vDeafEditor';
import { vDeafLists } from './variance/vDeafLists';
import { oWatcher } from './oWatcher';
import { vJobSeries } from './variance/vJobSeries';
import { vChat } from './variance/vChat';
import { vJobConfirm } from './variance/vJobConfirm';
import { oJobConfirm } from './oJobConfirm';
import { vActionList } from './variance/vActionList';
import { oDataEdit } from './oDataEdit';

export class AllJewels {
  oDataEdit = new oDataEdit();
  oDeaf = new oDeaf();
  oHours = new oHours();
  oJobConfirm = new oJobConfirm();
  oJobEdit = new oJobEdit();
  oJobSeek = new oJobSeek();
  oJobSeries = new oJobSeries();
  oStaffChat = new oStaffChat();
  oTerp = new oTerp();
  oTerpSched = new oTerpSched();
  oWatcher = new oWatcher();

  /* var store */
  vActionList = new vActionList();
  vBids = new vBids();
  vChat = new vChat();
  vCompany = new vCompany();
  vContact = new vContact();
  vCredReport = new vCredReport();
  vDeaf = new vDeaf();
  vDeafEditor = new vDeafEditor();
  vDeafLists = new vDeafLists();
  vFinder = new vFinder();
  vJobConfirm = new vJobConfirm();
  vJobUpdate = new vJobUpdate();
  vJobSeek = new vJobSeek();
  vJobSeries = new vJobSeries();
  vStaffNews = new vStaffNews();
  vTerp = new vTerp();
  vTerpSched = new vTerpSched();
  vTerpLists = new vTerpLists();

  /*
		---------------------------
			Move up when updated
		---------------------------
	*/

  nowTerp = new oNowTerp();
  credentials = new oCredentials();
  demands = new oDemands();
  terpChecklist = new oTerpChecklist();
  oStaffNews = new oStaffNews();

  /* details pages */
  jobs = new oJobs();
  company = new oCompany();
  seek = new oSeek(); // TODO: remove after updating AllActiveSeekers page
  account = new oAccount();
  billing = new oBilling();
  location = new oLocation();

  /* summaries */
  companyJobs = new oCompanyJobs();
  dash = new oDash();
  jobLists = new oJobLists();
  miscQueryReports = new oMiscQueryReports();

  /* utils */
  fileUpload = new oFileUpload();
  cron = new oCron();
  sqlMaint = new oSqlMaint();
  logs = new oLogs();
}
